'use client'
import * as React from 'react'
import { CardProps } from './types'
import Price from '../Price'
import Link from 'next/link'
import getVariantPrice from 'web/src/lib/util/getVariantPrice'

const Card = ({ title, image, prices, to }: CardProps) => {
    const price = getVariantPrice(prices)

    return (
        <Link
            className="hover:border-brand-red group flex flex-shrink-0 flex-col gap-y-4 border-t-2 border-transparent transition-all relative overflow-hidden"
            href={to}
        >
            {price?.calculatedPriceType === 'sale' && (
                <span className="absolute bg-brand-red text-white uppercase font-semibold tracking-wide px-10 -right-8 top-4 rotate-45">
                    Sale
                </span>
            )}
            {image}
            <span className="group-hover:text-brand-red font-medium transition-colors">{title}</span>
            <span>
                {price?.calculatedPriceType === 'sale' && (
                    <>
                        <Price
                            className="group-hover:text-brand-red transition-colors line-through opacity-50"
                            price={price.originalPrice}
                        />{' '}
                    </>
                )}
                {price?.calculatedPrice && (
                    <Price className="group-hover:text-brand-red transition-colors" price={price.calculatedPrice} />
                )}
            </span>
        </Link>
    )
}
export default Card
