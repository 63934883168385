'use client'
import * as React from 'react'
import { ModelheaderProps } from './types'
import { useStore } from 'web/src/lib/context/store-context'
import { useEffect } from 'react'
import { useCart } from 'medusa-react'

const ModelHeader = ({ content, payload }: ModelheaderProps) => {
    const { setContext } = useStore()
    const { cart } = useCart()
    useEffect(() => {
        if (cart?.id) {
            setContext({ ...cart?.context, ...payload })
        }
    }, [payload])

    return <>{content}</>
}

export default ModelHeader
